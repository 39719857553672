<!-- 平台账单 -->
<template>
  <div>
    <blockTitle title="平台账单"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <AppTable
            ref="AppTable"
            controller="platform/bill/"
            :tableColumns="tableColumns"
            :request-params="requestParams"
        >
          <template #left-operator>
            <div class="h-8 flex flex-row">
              <el-button-group>
                <el-button
                    v-for="(item, index) in stateList"
                    :key="index"
                    size="mini"
                    round
                    class="select-none"
                    :type="
                    requestParams.queryState === item.type ? 'primary' : ''
                  "
                    @click="changeStateType(item.type)"
                >
                  {{ item.label }}
                </el-button>
              </el-button-group>
            </div>
          </template>
          <template #operator="{ row }">
            <el-dropdown
                size="small"
                split-button
                @click="payBill(row)"
                v-if="requestParams.queryState === 1"
            >
              <template> 支付账单</template>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div
                      class="w-24 h-10 flex align-items-center"
                      @click="toBillContent(row)"
                  >
                    <i class="el-icon-wallet pr-1.5 text-base"></i>
                    查看明细
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-button
                size="small"
                @click="toBillContent(row)"
                v-if="requestParams.queryState === 2"
            >
              查看明细
            </el-button>
          </template>
        </AppTable>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '账单号',
          prop: 'billNo',
          search: true
        },
        {
          label: '账单金额',
          prop: 'money'
        },
        {
          label: '状态',
          prop: 'state'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      requestParams: {
        queryState: 2
      },
      stateList: [
        {
          type: 2,
          label: '已付款'
        },
        {
          type: 1,
          label: '待付款'
        }
      ]
    }
  },
  methods: {
    changeStateType (type) {
      this.requestParams.queryState = type
    },
    payBill () {
    },
    toBillContent (row) {
      this.$router.push({
        name: 'ptBillContent',
        query: {
          billNo: row.billNo
        }
      })
    }
  },
  mounted () {
  }
}
</script>
